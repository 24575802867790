@media (prefers-color-scheme: dark) {
  body {
    background: linear-gradient(160deg, #222 34%, #111 63%, #000 100%);
    background-attachment: fixed;
    animation: backgroundAnimation 15s ease infinite;
  }
}

@media (prefers-color-scheme: light) {
  body {
    background: linear-gradient(160deg, #fff 34%, #eee 63%, #ddd 100%);
    background-attachment: fixed;
    animation: backgroundAnimation 15s ease infinite;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  height: 100%;
}